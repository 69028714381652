<template>
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
import { tokenCheck } from '@/libs/auth-helper'

export default {
  name: 'TheMemberMobile',
  async beforeRouteUpdate (to, from, next) {
    this.emitter.emit('isSlot')
    const result = await tokenCheck()
    if (result) {
      next()
    } else {
      this.onCheck('front.error.afterSignin')
      location.href = '/'
    }
  }
}
</script>
<style scoped>
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
